import React, { Fragment } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { map } from "underscore";

import { locations } from "shared/constants";
import Layout from "components/styled/layout";
import Image from "components/styled/image";
import Text from "components/styled/text";

function Contact({ city }) {
  const restaurant = locations[city];
  return (
    <Layout className="columns align-items-center">
      <Layout className="column text-center col-sm-12 my-2">
        <Text bold block className="pb-4">
          {restaurant.address}
        </Text>
        <Text block secondary>
          Telefon
        </Text>
        <Text block className="pb-4">
          {restaurant.tel}
        </Text>
        <Text block secondary>
          E-mail
        </Text>
        <Text block>{city}@yumini.de</Text>
      </Layout>
      <Layout className="column col-auto col-sm-12 my-2">
        <Image
          src="/images/sushi-outline@2x.png"
          width="148"
          style={{ margin: "0 auto", paddingBottom: 10 }}
        />
      </Layout>
      <Layout className="column text-center col-sm-12 my-2">
        <Text className="pb-4" block bold>
          Hier können Sie parken
        </Text>
        {map(restaurant.parking, ({ name, location }, index) => (
          <Fragment key={name}>
            <Text block secondary>
              {name}
            </Text>
            <Text block className={classNames({ "pb-4": index === 0 })}>
              {location}
            </Text>
          </Fragment>
        ))}
      </Layout>
    </Layout>
  );
}

Contact.propTypes = {
  city: PropTypes.string,
};

export default Contact;
