import React from "react";
import PropTypes from "prop-types";
import { map } from "underscore";

import { locations } from "shared/constants";
import BackgroundContainer from "components/styled/background-container";
import Columns from "components/styled/columns";

function OpeningHourAndPrice({ title, openingHour, kitchen, price }) {
  return (
    <table className="table mb-4">
      <tbody>
        <tr>
          <td>
            <strong className="mr-2">{title}</strong> {openingHour[0]} -{" "}
            {openingHour[1]} Uhr
          </td>
          <td>Küche bis {kitchen} Uhr</td>
        </tr>
        {map(price, (value, key) => (
          <tr key={key}>
            <td>{key}</td>
            <td>{value}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

const kinderPrices = {
  siegen: "Kinder bis 11 Jahre essen zum halben Preis",
  bochum: "Kinder bis 12 Jahre, 10,9 € (Lunch), 15,9 € (Dinner)",
  dortmund: "Kinder bis 12 Jahre, 10,9 € (Lunch), 16,9 € (Dinner)",
  default: "Kinder bis 12 Jahre essen zum halben Preis",
  lüdenscheid: "Kinder bis 11 Jahre essen zum halben Preis",
};

const specialInfos = {
  dortmund: ["An Valentinstag & Silvester gelten die Preise vom Feiertag"],
  siegen: ["An Valentinstag & Silvester gelten die Preise vom Feiertag"],
  gelsenkirchen: ["Betriebsferien: 24.06.2024 - 07.07.2024"],
  lüdenscheid: ["An Valentinstag & Silvester gelten die Preise vom Feiertag"],
};

function OpeningHours({ city }) {
  const location = locations[city];
  const specialInfo = specialInfos[city] || [];
  const kinderPrice = kinderPrices[city] || kinderPrices.default;

  return (
    <Columns gapless>
      <BackgroundContainer column="col-sm-12 col-6" image="sushi-table.jpg" />
      <div column="col-sm-12 col-6" className="bg-gray p-6">
        <OpeningHourAndPrice title="Lunch" {...location.lunch} />
        <OpeningHourAndPrice title="Dinner" {...location.dinner} />
        {specialInfo.map((line) => (
          <p key={line} className="ml-2 mb-4">
            {line}
          </p>
        ))}
        <p className="ml-2">
          <strong>Kinder</strong>
          <br />
          Kinder bis {city === "siegen" ? 3 : 4} Jahre essen frei.
          <br />
          {kinderPrice}
        </p>
      </div>
    </Columns>
  );
}

OpeningHours.propTypes = {
  city: PropTypes.string,
};

export default OpeningHours;
