import React from "react";
import { map } from "underscore";

import { locations } from "shared/constants";
import Container from "components/styled/container";
import Columns from "components/styled/columns";
import Layout from "components/styled/layout";
import Link from "components/styled/link";
import Text from "components/styled/text";
import "../../styles/shops-column.scss";

function Locations() {
  return (
    <Container className="py-0">
      <div className="shops-column">
        {map(Object.keys(locations), (city) => (
          <Layout
            className="bg-light pt-3 pb-4 px-6"
            key={city}
            column="col-3 col-md-6 col-sm-12"
            style={{ margin: 1 }}
          >
            <Link to={`/${city}`} withArrow className="p-0">
              <Text bold uppercase large>
                {city}
              </Text>
            </Link>
            <Text tag="small" block>
              {locations[city].address.split(",")[0]}
            </Text>
          </Layout>
        ))}
      </div>
    </Container>
  );
}

export default Locations;
